<template>
    <l-map
        :style="$attrs.style || { 'min-height': '300px' }"
        :zoom="zoom"
        :center="center"
        ref="map"
        @baselayerchange="layerChanged"
        @ready="onReady"
    >
        <l-control-layers position="topright" :collapsed="false" :sort-layers="false" />
        <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :subdomains="tileProvider.subdomains"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
        />

        <l-marker
            v-if="marker"
            ref="markers"
            v-bind="marker.options"
            :lat-lng="marker.latLng"
            :data-marker-id="marker.id"
            :key="marker.id"
        >
        </l-marker>

        <slot />
    </l-map>
</template>

<script>
import { latLng } from "leaflet"
import MapTiles from "@Platon/components/map/MapTiles"
import "@Platon/components/map/VueLeafletImport"
import L from "leaflet/dist/leaflet"

/**
 * @typedef MapPickerMarker
 * @property {string|number} id
 * @property {LatLng} latLng
 * @property {Function|Vue.Component|string} popup
 * @property {object} markerData
 * @property {?object} options - Marker options, https://leafletjs.com/reference-1.7.1.html#marker-l-marker
 * @property {object} popupClasses
 * @property {object} popupStyles
 * @property {object} popupOptions
 * @property {Function} onMarkerClick
 */

export default {
    name: "MapViewer",

    data() {
        return {
            tileProviders: MapTiles,
            map: null
        }
    },

    props: {
        marker: {},

        zoom: {
            default: 16,
            type: Number
        }
    },

    computed: {
        center() {
            return (this.marker && this.marker.latLng) || latLng(41.311081, 69.240562)
        }
    },

    methods: {
        onReady(map) {
            this.map = map
        },

        layerChanged(layer) {
            this.$nextTick(() => {
                const map = this.map
                const center = map.getCenter()
                if (layer.name.includes("Yandex")) {
                    map.options.crs = L.CRS.EPSG3395
                } else {
                    map.options.crs = L.CRS.EPSG3857
                }
                map.setView(center)
                map._resetView(map.getCenter(), map.getZoom(), true)
            })
        }
    }
}
</script>

<style></style>
